@media screen and (max-width:991.98px) {
  .side-nav-wrapper {
    position: fixed;
    z-index: 999;
    transform: translateX(0);
    width: 235px !important;

    &.menu-collapsed {
      transform: translateX(-100%);
    }
  }

  .side-nav-outer {
    .menu-humBurger {
      display: block !important;
    }
  }

  .side-nav-outer:not(.menu-collapsed-o) {
    .menu-sm-overlay {
      position: fixed;
      width: 100vw;
      height: 100vh;
      display: block;
      opacity: 0.2;
      background: black;
      z-index: 2;
    }
  }
}

@media screen and (max-width: 767px) {
  .mobileNav-loc_cl-selector {
    display: block !important;
  }

  .hide-sm {
    display: none !important;
  }

  .help-button {
    bottom: 10px;
    height: 38px !important;
    width: 38px !important;
    padding: 6px !important;
    background: #1b2429;
    border: 1px solid rgba(0, 248, 162, 0.2117647059) !important;
    box-shadow: 0 0 20px -11px #00ffa770;
  }

  .nav-items {
    margin-top: 10px;
  }

  .page-main-card {
    padding: 16px 0 !important;

    .mat-mdc-card-content {
      padding: 0 8px !important;

      .table-card {
        padding: 6px 0;
      }
    }

    .choose-date {
      .matMini-icon-btn {
        width: 30px !important;
        height: 30px !important;
        padding: 3px !important;

        .mat-mdc-button-touch-target {
          height: 32px;
          width: 32px;
        }
      }
    }
  }

  .table-mobile-res {
    .mat-mdc-paginator-range-label {
      display: none;
    }
  }

  .fullView-mobileDialog.mat-mdc-dialog-panel {
    width: 100%;
    min-width: 100% !important;
    height: 100%;

    .mdc-dialog__container {
      .mdc-dialog__surface {
        border-radius: 0 !important;
      }

      .mat-mdc-dialog-content {
        flex: 1 1 auto;
        min-height: 1px;
        max-height: 100%;
        padding: 15px;
        padding-top: 20px;
      }

      .mat-mdc-dialog-title {
        padding: 8px 10px;
      }

      .modal-width {
        width: 100% !important;
        display: flex;
        flex-direction: column;
        height: 100%;
        max-width: 100%;
      }
      .footer-with-Id{
        flex-wrap: wrap;
        justify-content: end;
      }
    }
  }

  .hide-sm {
    display: none;
  }

  .layout-fixed-sm {
    table-layout: fixed !important;
    width: 100%;
  }

  .container-fluid {
    padding-left: 10px;
    padding-right: 10px;
  }

  .mdc-data-table__cell,
  .mdc-data-table__header-cell {
    padding: 0 8px 0 8px !important;
  }

  .show-sm {
    display: table-row !important;
  }

  .table-to-card-mb {
    table {
      table-layout: fixed;
      width: 100%;

      thead {
        tr {
          display: block;
          height: auto;
          padding: 7px;
          background: #1e282d;
          box-shadow: 0 0 12px -5px #020202;
          border-radius: 10px;
          overflow: auto;
          margin-bottom: 15px;
          border: 1px solid rgba(0, 248, 162, 0.2117647059) !important;

          &::-webkit-scrollbar {
            display: none;
          }

          th {
            .table-header-content-text {
              white-space: nowrap;

            }
          }
        }
      }

      tbody {
        display: grid;
        flex-wrap: wrap;
        gap: 12px;
        grid-template-columns: repeat(auto-fit, minmax(275px, 1fr));
        padding-bottom: 12px;

        tr:not(.expandable-row, .expandable-inner-row),
        td {
          display: block;
          border: 0;

          &.right-corner {
            position: absolute;
            right: 0;
            width: fit-content;
            bottom: 9px;
          }

          &.left-corner {
            position: absolute;
            bottom: 0px;
            left: 0;
            right: auto;
          }

          &.bottom-full-w {
            width: 100%;
            background: #00000038;

            >div {
              width: fit-content !important;
            }
          }

          &.bg-transparent-sm {
            background-color: transparent;
          }

          .row-reverse {
            flex-direction: row-reverse;
          }

          .grid-control-column {
            opacity: 1;
          }
        }

        td:not(.static-td) {
          padding: 6px 10px !important;

          &::before {
            content: attr(dataName);
            width: 110px;
            display: inline-block;
            text-transform: capitalize;
            font-weight: normal;
          }

          span {
            padding-left: 12px;
          }

        }

        tr:not(.expandable-row, .expandable-inner-row) {
          padding: 8px;
          height: auto;
          border-radius: 10px !important;
          border: 1px solid rgba(0, 248, 162, 0.2117647059) !important;
          -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
          background-color: rgb(27 36 41) !important;
          overflow: hidden;
          padding-bottom: 3rem;
        }

        .expandable-row {
          display: none;
        }

        .expandable-inner-row {
          display: flex;
          gap: 10px
        }

        .expanded-row+.row-detail.expandable-row {
          display: block !important;
          position: absolute !important;
          z-index: 10 !important;

          td:not(.expandable-inner-col) {
            position: fixed;
            left: 5px;
            width: 96vw;
            max-height: 90vh;
            background-color: rgba(16, 26, 32, 0.7490196078) !important;
            /* border-radius: 20px !important; */
            border: 1px solid #00F8A2;
            -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
            background: linear-gradient(225deg, rgba(255, 255, 255, 0.1) 0%, rgba(100, 100, 100, 0.1) 25%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1) 75%, rgba(100, 100, 100, 0.1) 100%);
            box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.1019607843), inset 0 0 7px 1px rgba(255, 255, 255, 0.1019607843), 0 0 7px rgba(0, 0, 0, 0.2509803922) !important;
            padding: 40px 13px !important;
            min-height: 50vh;
            border-radius: 10px;
            top: 0;
            bottom: 0;
            margin: auto;
            height: fit-content;
            overflow: auto;

            mat-icon.close-expandable {
              position: absolute;
              right: 12px;
              top: 10px;
              cursor: pointer;
              ;
            }
          }

          &::before {
            content: "";
            position: fixed;
            height: 100vh;
            width: 100vh;
            left: 0;
            top: 0;
            background: black;
            opacity: 0.4;
          }
        }
      }
    }
  }

  .sm-grid-areas {
    display: grid !important;
    grid-template-areas:
      'add add add add download filter toggle'
      'date date date date date date date';
    gap: 12px;
    padding: 0 10px;

    .add-area {
      grid-area: add;
      max-width: 230px;
    }

    .date-area {
      grid-area: date;
    }

    .download-area {
      grid-area: download;
    }

    .filter-area {
      grid-area: filter;
    }

    .toggle-area {
      grid-area: toggle;
      max-width: 100px;
    }
  }
}

@media (max-width:480.99px) {
  .sm-grid-areas {

    // grid-template-areas:
    // 'add add download filter toggle toggle'
    // 'date date date date date date';
    .toggle-area {
      max-width: 84px;
    }

    .add-area {
      max-width: 40px;
      min-width: 30px;
      padding: 0;

      mat-icon {
        margin: 0;
      }

      .mdc-button__label {
        display: none;
      }
    }

    .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
      padding: 0 8px !important;
    }
  }
}
