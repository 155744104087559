@import './material-dark-theme.scss';
@import './bootstrap.css';
@import './responsive.scss';
/*@import 'bootstrap/dist/css/bootstrap.min.css'; */
//NOTE - uncommenting this breaks up UI - with or without side-help
/*TODO -- IMPORTANT - side-nav help is changing the font-style and/or weight of data in tables - otherwise. Need to check and revert it*/
@import 'prismjs/themes/prism-okaidia.css';
@import 'prismjs/plugins/line-numbers/prism-line-numbers.css';
@import 'prismjs/plugins/line-highlight/prism-line-highlight.css';

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

mat-hint {
  font-size: 12px;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #131a2387;
}

::-webkit-scrollbar-thumb {
  background: #ffffff1f;
  border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
  background: #ffffff1f;
}

.cstm-color {
  color: #00ffa7;
}

.page-main-card {
  padding: 30px;
  padding-top: 22px;
}

.f-grow {
  flex: 1 1 auto;
}

.page-top-space {
  margin-top: 0.6rem;
  margin-bottom: 2rem;
}

.page-action {
  margin-bottom: 3rem;

  .choose-date {
    flex: 1;
    display: flex;
    align-items: center;
  }

  .tabs-selection {
    flex: 1.8;

    .mat-mdc-tab-links {
      justify-content: start !important;
      border-bottom: 1px solid rgba(217, 217, 217, 0.38);
    }

    .mat-mdc-tab-link {

      .mdc-tab__text-label {
        color: #FFFFFF;
      }

      &.mdc-tab--active {
        .mdc-tab__text-label {
          color: #00ffa7;
        }
      }

      .mdc-tab-indicator__content--underline {
        border-top-width: 3px;
        border-radius: 5px 5px 1px 0px !important;

      }
    }

    .mdc-tab {
      padding-right: 15px;
      padding-left: 15px;
      flex-grow: unset !important;
      text-decoration: none;
    }

    &.mat-mdc-tab-header-pagination-controls-enabled {
      .mat-mdc-tab-header-pagination:not(.mat-mdc-tab-header-pagination-disabled) {
        box-shadow: 1px 0px 14px 0px #0c0f10;
      }
    }

  }

}

.matMini-icon-btn {
  width: 36px !important;
  height: 36px !important;
  padding: 6px !important;
}

.mat-button-toggle-appearance-standard {
  background-color: transparent !important;

  &.mat-button-toggle-checked {
    background-color: #00FFA7 !important;

    .mat-button-toggle-label-content {
      color: #131a23;
    }

  }

  .mat-button-toggle-button {
    .mat-button-toggle-label-content {
      line-height: 45px;
    }
  }

}

//will move to responsive later
@media (min-width: 1350px) {
  .container {
    max-width: 1350px;
  }
}

@media (min-width: 1420px) {
  .container {
    max-width: 1410px;
  }
}


//GRID CSS WILL MOVE TO SEPERATE FILE
.table-card {
  padding: 12px;
  // background: #243036;
  background: #24303657;
}

.table-body {
  padding: 8px;
}

.expandable-table {
  .mat-mdc-table {
    .mat-mdc-row {

      &:nth-child(4n+1),
      &:nth-child(4n+2) {
        background: rgb(19 26 35 / 17%);
      }

      &.expanded-row {
        border-bottom: 1px solid rgba(217, 217, 217, 0.38);
      }
    }
  }
}

.mat-elevation-z8:not(.expandable-table),
.table-styled:not(.expandable-table) {
  .mat-mdc-table {
    .mat-mdc-row {
      &:nth-child(odd) {
        background: rgb(19 26 35 / 17%);
      }
    }
  }
}

.mat-elevation-z8,
.table-styled {
  box-shadow: unset;

  .mat-mdc-table {
    background-color: transparent;

    .mat-mdc-row {
      position: relative;
      transition: 0.27s ease all;


      &:hover {
        box-shadow: 0 0 5px -3px #00ffa7;
        z-index: 1;
        position: relative;
      }

    }

    .mat-mdc-cell,
    .mat-mdc-header-cell,
    .mat-mdc-row {
      border: 0;

      .mat-mdc-icon-button.mat-mdc-button-base {
        --mdc-icon-button-state-layer-size: 40px;
        width: 40px;
        height: 40px;
        padding: 7px;

        .mat-icon {
          height: 20px;
          width: 20px;

          &.material-icons {
            font-size: 20px;
          }
        }
      }
    }

    .mat-mdc-header-cell {
      background: #1e282d;
      z-index: 8 !important;
    }
  }

  tr {
    td.listing-table-tools-wrapper {
      overflow: visible;
      position: sticky;
      right: 0;

      .grid-control-column {
        // position: absolute;
        width: 100%;
        top: 0;
        right: 0;
        min-width: fit-content;
        z-index: 99;
        justify-content: end;
        pointer-events: none;
        display: flex;
        opacity: 0;
        // background: #131a2369;
        height: 100%;

        * {
          pointer-events: all;
        }
      }
    }

    &:hover {

      td.listing-table-tools-wrapper {
        .grid-control-column {
          opacity: 1;
        }
      }
    }

  }

  .mat-mdc-paginator {
    background-color: #1e282d;
  }
}

.table-body {
  overflow-x: auto;
}

.mat-mdc-dialog-container {
  .mdc-dialog__container {
    .mdc-dialog__surface {
      // background-color: #243036 !important;
      // border-radius: 20px;
      background-color: #101a20bf !important;
      border-radius: 20px !important;
      border: 1px solid #00F8A2;
      -webkit-backdrop-filter: blur(10px);
      backdrop-filter: blur(10px);
      background: linear-gradient(225deg, rgba(255, 255, 255, .1) 0%, rgba(100, 100, 100, .1) 25%, rgba(0, 0, 0, .1) 50%, rgba(0, 0, 0, .1) 75%, rgba(100, 100, 100, .1) 100%);
      box-shadow: inset 0 0 0 1px #ffffff1a, inset 0 0 7px 1px #ffffff1a, 0 0 7px #00000040 !important;
    }

    .mat-mdc-dialog-actions {
      justify-content: end;
      padding: 10px 24px 20px 24px;
    }

    .mdc-dialog__title {
      font-size: 18px;
      font-weight: 500;
    }
  }

}

.modal-width {
  width: 50vw;
  max-width: 600px;
}

.mdc-dialog__title+.mdc-dialog__content,
.mdc-dialog__header+.mdc-dialog__content {
  padding-top: 24px !important;
}

.timepicker-backdrop-overlay {
  z-index: 1000 !important;
}

.timepicker-overlay {
  z-index: 1000 !important;

  .timepicker__header,
  .timepicker__actions,
  .clock-face {
    background-color: #17262f !important;
  }

  .timepicker__body {
    background-color: #1b2429 !important;
  }
}


//card css styling
.transparent-effect-card {
  background: linear-gradient(225deg, rgba(255, 255, 255, 0.05) 0%, rgba(100, 100, 100, 0.05) 25%, rgba(0, 0, 0, 0.05) 50%, rgba(0, 0, 0, 0.05) 75%, rgba(100, 100, 100, 0.05) 100%);
  /* box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.1019607843), inset 0 0 7px 1px rgba(255, 255, 255, 0.1019607843), 0 0 7px rgba(0, 0, 0, 0.2509803922) !important; */
  border-radius: 10px !important;
  border: 1px solid #00f8a236 !important;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(0, 0, 0, 0.1019607843) !important;

  &.dynamic-card {
    border: 1px solid #00f8a2db !important;
  }

  .mat-mdc-card-header {
    padding: 24px 24px 9px;
  }

  .mat-mdc-card-content {
    padding: 24px;
    @media (max-width: 600px) {
      padding: 14px;
    }
  }

  .mdc-card__actions {
    padding: 10px 24px 20px 24px;
  }
}

//toggle swtich design
.mat-mdc-slide-toggle {
  .mdc-switch {
    width: 52px;

    .mdc-switch__track {
      border-radius: 99px;
      height: 32px;

      &:before {
        background: #131a23 !important;
        border-color: #74777f;
        border-width: 2px;
      }
    }

    .mdc-switch__handle {
      width: 24px !important;
      height: 24px !important;
      margin: 0 4px !important;
      border-radius: 99px;
    }
  }

  .mdc-switch--selected {
    .mdc-switch__handle {
      margin: 0 -8px !important;
    }

    .mdc-switch__shadow {
      background: #1b2429;
    }

    .mdc-switch__icon {
      fill: #00f79a !important;
    }
  }
}

.clr-primary {
  color: #00ffa7 !important;
}

.isLogout {
  .help-button {
    display: none;
  }
}

.wide-options-panel {
  min-width: fit-content !important;
}

.filter-string {
  text-align: left;
  font-size: 1em;
  color: gray;
  font-style: italic;
  margin: 0.2em;
}

.menu-sm-overlay {
  display: none;
}

.footer-with-Id {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  flex-wrap: wrap-reverse;
  padding: 10px 24px 20px 24px !important;

  .mat-mdc-dialog-actions {
    width: fit-content !important;
    padding: 0 !important;
  }

  .copyEntityId {
    width: fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
    align-items: center;
    padding: 0 !important;

    input {
      background: transparent;
      color: white;
      border: 0;
      pointer-events: none;
      outline: unset;
      font-size: 14px;
      opacity: 0.5;
      padding: 0;
    }

    button {
      padding: 5px !important;
      height: 35px !important;
      width: 35px !important;

      mat-icon {
        height: 19px;
        width: 19px;
        font-size: 19px;
        color: white !important;
        opacity: 0.6;
      }
    }
  }
}
.full-width-modal {
  width: 100%;
  height: calc(100% - 32px);
  width: calc(100% - 80px) !important;
  max-width: 1100px !important;
  /* min-width: 100%; */
  .mat-mdc-dialog-container {
    width: 100%;
    max-width: 100%;
    // .mdc-dialog__surface{
    //   border-radius: 0 !important;
    // }
    .large-modal,.modal-width{
      height: 100%;
        display: flex;
        flex-direction: column;
        .mat-mdc-dialog-content{
          flex: 1 1 auto;
          min-height: 1px;
          overflow: auto;
          max-height: 100%;
        }
    }
}
}
.table-header-container {
  display: flex;
  align-items: center;
}
